import { MEMBERS_AREA } from '@wix/app-definition-ids';
import type { AppExposedApis } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import {
  Experiment,
  MEMBER_SETTINGS_TPA_PAGE_ID,
  MEMBER_TPA_PAGE_ID,
} from '../../constants';
import { EDITOR_ACTION } from '../../constants/editor-actions';
import { globalAppState } from '../../editor/services';

const getActionId = (tpaPageId: string | undefined) => {
  if (tpaPageId === MEMBER_TPA_PAGE_ID) {
    return EDITOR_ACTION.OPEN_UNIFIED_MANAGER_PANEL;
  } else if (tpaPageId === MEMBER_SETTINGS_TPA_PAGE_ID) {
    return EDITOR_ACTION.OPEN_SETTINGS_PAGE_UNIFIED_MANAGER_PANEL;
  } else {
    return null;
  }
};

export const createEditorEventsHandlers = (
  editorSDK: FlowEditorSDK,
): AppExposedApis['editor'] => ({
  // This app should be deleted from "beforeRemoveApp" handler in the umbrella platform app
  // However uninstallation gets stuck when done from there for some reason
  removeAppCompleted: async ({ appDefinitionId }) => {
    const flowAPI = globalAppState.getFlowAPI();

    const isUninstallMASubAppsEnabled = flowAPI
      ? flowAPI.experiments.enabled(Experiment.EnableUninstallMASubApps)
      : false;

    if (isUninstallMASubAppsEnabled) {
      return;
    }

    if (appDefinitionId === MEMBERS_AREA) {
      await editorSDK.application.uninstall('', { openConfirmation: false });
    }
  },
  getDynamicPageNavBarConfig: async ({ pageRef, numberOfItems }) => {
    const flowAPI = globalAppState.getFlowAPI();

    const pageData = await editorSDK.pages.getPageData('', { pageRef });
    const actionId = getActionId(pageData.tpaPageId);

    if (flowAPI && pageData.managingAppDefId === MEMBERS_AREA && actionId) {
      return {
        navBarMainAction: {
          label: flowAPI.translations.t(
            'app.page.sausage-bar.cta.manage-member-pages.title',
          ),
          actionId,
        },
        numberAndLabel: flowAPI.translations.t(
          'app.page.sausage-bar.tabs-label',
          { tabsCount: numberOfItems },
        ),
      };
    }

    return {};
  },
});
